import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="skip-link">
        </div>
        {" "}
        <div id="wrapper">
          <div id="head">
            <div id="header">
              <div id="navigation">
                <a id="logo" href="/" />
                <div className="region region-search">
                  <div id="block-search-form" className="block block-search">
                    <div className="content">
                      <form
                        action="/loperamid"
                        method="post"
                        id="search-block-form"
                        acceptCharset="UTF-8"
                      >
                        <div></div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="metatext">
                Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                </div>
                <div className="meta">
                  <a href="/faq-page/">FAQ</a>
                  <a href="/glossar/">Glossar</a>
                  <a href="/kontakt/">Kontakt</a>
                </div>
                <div className="main">
                  <ul id="main-menu" className="links inline clearfix">
                    <li className="menu-597 active-trail first">
                      <a
                        href="/loperamid/"
                        className="active-trail"
                      >
                        Wie wirkt IMODIUM<sup>®</sup>?
                      </a>
                    </li>
                    <li className="menu-690">
                      <a href="/imodium-produkte/" title>
                        Produkte
                      </a>
                    </li>
                    <li className="menu-727">
                      <a href="/fakten-uber-durchfall/">
                        Durchfall - Ursachen &amp; Behandlung
                      </a>
                    </li>
                    {/* <li className="menu-331">
                      <a href="/diagnose-reizdarm/">Info zu Reizdarm</a>
                    </li> */}
                    <li className="menu-627">
                      <a href="/reisedurchfall-vorbeugen/">
                        Durchfall auf Reisen
                      </a>
                    </li>
                    <li className="menu-337">
                      <a href="/durchfall-bei-kindern/">
                        Durchfall bei Kindern
                      </a>
                    </li>
                    <li className="menu-615 last">
                      <a href="/infomaterial/" title>
                        Infomaterial
                      </a>
                      <span class="info_icon"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="content">
            <div className="sidebar">
              <div className="region region-subnavi">
                <div
                  id="block-menu-menu-wie-wirkt-imodium-"
                  className="block block-menu"
                >
                  <h2>Wie wirkt Imodium® akut?</h2>
                  <div className="content">
                    <ul className="menu">
                      <li className="first leaf">
                        <a href="/loperamid/" title>
                          Wie wirkt IMODIUM®?
                        </a>
                      </li>
                      <li className="last leaf active-trail">
                        <a
                          href="/loperamid/"
                          title
                          className="active-trail active"
                        >
                          Der Wirkstoff: Loperamid
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              &nbsp;
            </div>
            <div className="content_c">
              <div className="region region-content">
                <div id="block-system-main" className="block block-system">
                  <div className="content">
                    <h1>
                      <div className="field field-name-field-title-text field-type-text field-label-hidden">
                        <div className="field-items">
                          <div className="field-item even">
                            Der Wirkstoff: Loperamid{" "}
                          </div>
                        </div>
                      </div>
                    </h1>
                    <div className="std-site">
                      <div div className="firstrow">
                        <div className="left">
                          <div className="quote">
                            <span>
                              <h2>
                                <div className="field field-name-field-center-title field-type-text-long field-label-hidden">
                                  <div className="field-items">
                                    <div className="field-item even">
                                      Wirkstoff mit langer Erfahrung
                                    </div>
                                  </div>
                                </div>{" "}
                              </h2>
                            </span>
                          </div>
                        </div>
                        <div className="right">
                          <p className="subline">
                            In der Schweiz ist der Wirkstoff Loperamid bereits
                            seit 1977 unter dem Namen IMODIUM® auf dem Markt.
                            Weltweit ist IMODIUM® in mehr als 90 Ländern
                            erhältlich.
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                      <div div className="secondrow">
                        <div className="left">
                          <div className="field field-name-field-sidebar-text field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div className="field-item even">
                                <h3>Vorteile von Loperamid</h3>
                                <ul>
                                  <li>
                                    <p>
                                    Loperamid kann einer möglichen Dehydrierung bei Durchfall vorbeugen.&nbsp;
                                      <span
                                        style={{
                                          "font-size": "13.008px",
                                          "line-height": "1.538em"
                                        }}
                                      >
                                        Dem Verlust von Flüssigkeit und
                                        Elektrolyten wird entgegengewirkt.
                                      </span>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Die Wirkung von Loperamid kann nach etwa 2
                                      Stunden eintreten.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Zudem unterstützt Loperamid den Körper,
                                      die Konsistenz des Stuhls wieder zu
                                      verfestigen.
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="right">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <h3>Was passiert bei Durchfall im Darm?</h3>
                                <p>
                                  Die klassischen Durchfallsymptome – zu
                                  häufiger und zu wässriger Stuhl – kennen die
                                  meisten Menschen aus eigener Erfahrung. Doch
                                  was geht eigentlich bei Durchfall im Darm vor
                                  sich?
                                </p>
                                <p>
                                  Drei veränderte Funktionszustände lassen sich
                                  bei Durchfall im Darm feststellen:
                                </p>
                                <ul>
                                  <li>
                                    Erhöhte Motilität: Durch eine beschleunigte
                                    Darmtätigkeit wird der Darminhalt schneller
                                    als normalerweise zum Darmausgang befördert.
                                  </li>
                                  <li>
                                    Verringerte Resorption (Aufnahme) von
                                    Flüssigkeit aus dem Darm: Elektrolyte und
                                    Flüssigkeit aus der Nahrung werden bei
                                    normaler Verdauung über den Darm in den
                                    Körper aufgenommen. Wenn der Darm aber
                                    schneller arbeitet als üblich, bleibt dem
                                    Körper weniger Zeit für die Resorption. So
                                    verbleibt mehr Flüssigkeit im Stuhl - er
                                    wird wässrig. Infolge der verringerten
                                    Aufnahme von Elektrolyten und Flüssigkeit
                                    kann eine Austrocknung (Dehydrierung) des
                                    Körpers drohen.
                                  </li>
                                  <li>
                                    Erhöhte Sekretion: Gleichzeitig führt der
                                    Körper dem Darm vermehrt Elektrolyte und
                                    Flüssigkeit zu – der Stuhl verflüssigt sich
                                    zunehmend und der Stuhldrang verstärkt sich.
                                    <br />
                                    &nbsp;
                                  </li>
                                </ul>
                                <h3>
                                  Loperamid kann dabei helfen die Darmfunktionen zu normalisieren
                                </h3>
                                <p>
                                  Der Wirkstoff Loperamid hat Einfluss auf die
                                  drei oben genannten Funktionsstörungen. So kann sich 
                                  der Stuhl wieder verfestigen und einer Austrocknung des Körpers
                                  kann entgegengewirkt werden.
                                </p>
                                <ul>
                                  <li>
                                    Loperamid bewirkt eine Verminderung der
                                    erhöhten Darm-Motilität.
                                  </li>
                                  <li>
                                    Dadurch verweilt die Nahrung länger im Darm
                                    und der Körper kann mehr Elektrolyte und
                                    Flüssigkeit daraus resorbieren.
                                  </li>
                                  <li>
                                    Ebenso wird die erhöhte Sekretion von
                                    Elektrolyten und Flüssigkeit in den Darm
                                    wieder herabgesetzt.
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <Footer></Footer>
        <div id="disclaimer">
          <div className="wrap">
            <div className="region region-copyright">
              <div id="block-block-1" className="block block-block">
                <div className="content">
                  <p>
                    Dies ist ein zugelassenes Arzneimittel. Lassen Sie sich von einer Fachperson beraten und lesen Sie die Packungsbeilage.
                  </p>
                  <p>
                    © JNTL Consumer Health II (Switzerland) GmbH 2011-2023. Diese Website wird durch die
                    JNTL Consumer Health II (Switzerland) GmbH, Zug veröffentlicht, die die alleinige
                    Verantwortung für den Inhalt trägt.
                    <br />
                    Diese Website richtet sich ausschliesslich an Benutzer aus
                    der Schweiz. Letzte Aktualisierung am: 01. Februar 2023, CP-216083
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="externlink">
          <div className="close modalClose" />
          <div className="hl">
            Sie verlassen jetzt
            <br />
            unsere Webseite
          </div>
          <p>
            Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
            Inhalt wir nicht verantwortlich sind und auf die unsere{" "}
            <a href="/datenschutz/">Datenschutzbestimmungen</a> keine Anwendung
            finden.
          </p>
          <div>
            <br />
            <a id="externlink_close" className="btn modalClose" href="#">
              <span className="start">Abbrechen</span>
              <span className="end" />
            </a>
            <a id="externlink_next" className="btn" href="#">
              <span className="start">Weiter</span>
              <span className="end" />
            </a>
            <div className="clear" />
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
